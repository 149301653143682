import CustomerSearchResultsHtml from './templates/customers.search.results.html';

export function CustomerLiteCtrl (
  $q,
  $scope,
  $state,
  $translate,
  blockUI,
  Customer,
  messages,
  sessionObjAPI,
  streamSearch,
  workshopAPI
) {

  $scope.block                            = blockUI.instances.get('customerBlock');
  $scope.currency                         = sessionObjAPI.currency();
  $scope.customerSearchResultsTemplateUrl = CustomerSearchResultsHtml;
  $scope.loaded                           = true;
  $scope.showContactDetails               = false;

  $scope.Customer = new Customer($scope.customer);

  $scope.addressSearch = {
    list    : [],
    selected: {
      id          : '',
      full_address: ''
    },
    choose: ( address, type = 'address' ) => {
      $scope.block.start();

      return workshopAPI
      .get('/NzPostal/get_address_details', address.address_id)
      .then(details => {
        switch (type) {
          case 'postal':
            $scope.customer.postal_address1  = details['address1'];
            $scope.customer.postal_address2  = details['address2'];
            $scope.customer.postal_suburb    = details['suburb'];
            $scope.customer.postal_state     = details['state'];
            $scope.customer.postal_postcode  = details['postcode'];
            break;
          case 'address':
          default:
            $scope.customer.address1     = details['address1'];
            $scope.customer.address2     = details['address2'];
            $scope.customer.suburb       = details['suburb'];
            $scope.customer.state        = details['state'];
            $scope.customer.postcode     = details['postcode'];
            $scope.customer.country_code = details['country_code'];
            $scope.customer.latitude     = details['latitude'];
            $scope.customer.longitude    = details['longitude'];
            break;
        }
      })
      .finally(() => {
        $scope.block.stop();
      });
    },
    format: ( address ) => angular.isString(address) ? address : '',
    search: typed => workshopAPI
      .get('/NzPostal/get_addresses', typed)
      .then(addresses => addresses !== 'null' ? addresses : '')
  };

  $scope.billerSearch = {
    list  : [],
    choose: customer => {
      $scope.customer.biller_id   = customer.id           || null;
      $scope.customer.biller_name = customer.display_name || null;
    },
    format: customer => customer ? customer.display_name: '',
    search: typed => streamSearch('customer_billers', typed).then(customers => $scope.billerSearch.list.concat(customers))
  };

  $scope.delete = function () {
    $scope.Customer
      .delete()
      .then(() => {
        $state.go('app.customers');

        messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.CUSTOMER'), 'success');
      })
      .catch(err => messages.error(err));
  };

  $scope.emailStatement = function () {
    if (!$scope.form.$pristine || !$scope.customer.id) {
      messages.show($translate.instant('JS_SPACE.MESSAGES.MUST_SAVE_CUSTOMER_BEFORE_EMAILING'), 'warning');

      return;
    }

    $scope.Customer.emailStatement()
    .then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.EMAIL_SENT'), 'success'));
  };

  $scope.getBiller = function (id) {
    workshopAPI.get('/customer', id)
    .then(customer => {
      $scope.billerSearch.selected = {
        display_name: customer.display_name,
        id          : customer.id
      };

      $scope.customer.biller_name = customer.display_name;
    })
    .catch(err => messages.error(err));
  };

  $scope.init = function () {
    if ($scope.customer.price_type === '5' && $scope.customer.price_matrix_id) {
      $scope.customer.price_type = $scope.customer.price_matrix_id;
    }

    if ($scope.customer.biller_id) {
      $scope.getBiller($scope.customer.biller_id);
    }
  };

  $scope.print = function () {
    if (!$scope.form.$pristine || !$scope.customer.id) {
      messages.show($translate.instant('JS_SPACE.MESSAGES.MUST_SAVE_CUSTOMER_BEFORE_PRINTING'), 'warning');

      return;
    }

    $scope.Customer.printStatement();
  };

  $scope.save = function (form) {
    if ($scope.customer.tax_group_id) {
      $scope.customer.tax_group_name = $scope.taxGroups.filter(group => group.id === $scope.customer.tax_group_id)[0].name;
    }

    if (document.getElementsByName('biller')[0].value === '') {
      $scope.customer.biller_id = null;
    }

    $scope.Customer
      .save()
      .then(customer => {
        form.$setPristine();
        $scope.customer = customer;
        $scope.Customer = new Customer($scope.customer);

        messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.CUSTOMER'), 'success');

        $state.go('app.customer', {
          id: $scope.customer.id
        }, {
          reload: true
        });
      })
      .catch(err => messages.error(err));
  };

  $scope.sendPortalInvitation = function () {
    $scope.Customer.sendPortalInvitation()
    .then(() => {
      $state.reload();
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.sendEmail = function (email) {
    $scope.Customer.email(email, $scope.companyMessages.customer_email_message)
    .then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success'))
    .catch(err => messages.error(err));
  };

  $scope.sendSms = function () {
    $scope.Customer.sms($scope.customer)
    .then(() => messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success'))
    .catch(err => messages.error(err));
  };

  $scope.syncWithHeadOffice = function () {
    $q.when()
    .then(() => $scope.form.$dirty ? $scope.Customer.save() : $q.when())
    .then(() => workshopAPI.patch('/customers/sync_multibranch_customer_entry', $scope.customer))
    .then(() => {
      messages.show($translate.instant('MULTI_BRANCH_SPACE.CUSTOMER_LINKED'), 'success');

      $scope.form.$setPristine();

      $state.reload();
    })
    .catch(err => messages.error(err));
  };

  $scope.validateCompanyName = function ( name ) {
    return $scope.Customer.validateCompanyName(name);
  };

  $scope.validateFirstAndLastName = function ( name, field = 'first_name' ) {
    return $scope.Customer.validateFirstAndLastName(name, field);
  };

  $scope.init();
}