import VendorSearchResultsHtml  from './templates/vendors.search.results.html';

export function VendorLiteCtrl (
  $q,
  $scope,
  $state,
  $stateParams,
  $translate,
  branchesApi,
  confirm,
  messages,
  messengerApi,
  partstechApi,
  sessionObjAPI,
  streamSearch,
  Vendor,
  workshopAPI
  ) {

  $scope.branchInvoices  = [];
  $scope.branchPayments  = [];
  $scope.currency        = sessionObjAPI.currency();
  $scope.loaded          = true;

  $scope.Vendor    = new Vendor($scope.vendor);

  $scope.addressSearch = {
    list    : [],
    selected: {
      full_address: '',
      id          : ''
    },
    search (typed) {
      return workshopAPI.get('/NzPostal/get_addresses', typed)
      .then(addresses => addresses !== 'null' ? addresses : '');
    },
    choose (address) {
      return workshopAPI.get('/NzPostal/get_address_details', address.address_id)
      .then(details => {
        $scope.vendor.address1     = details.address1;
        $scope.vendor.address2     = details.address2;
        $scope.vendor.country_code = details.country_code;
        $scope.vendor.latitude     = details.latitude;
        $scope.vendor.longitude    = details.longitude;
        $scope.vendor.postcode     = details.postcode;
        $scope.vendor.state        = details.state;
        $scope.vendor.suburb       = details.suburb;
      });
    },
    format () {
      return angular.isString($scope.vendor.address1) ? $scope.vendor.address1 : '';
    }
  };

  $scope.billerSearch = {
    list: [],
    search (typed) {
      return streamSearch('vendor_billers', typed)
      .then(vendors => {
        return $scope.billerSearch.list.concat(vendors);
      });
    },
    choose (vendor) {
      $scope.vendor.biller_id   = vendor.id;
      $scope.vendor.biller_name = vendor.company_name;
    },
    format (vendor) {
      return vendor ? vendor.company_name: '';
    },
    templateUrl: VendorSearchResultsHtml
  };

  $scope.autolink = function () {
    workshopAPI.get('/vendors/autolink_to_branch', $scope.vendor.id)
    .then(count => {
      messages.show(`Autolink Created! ${count} Record(s) Linked.`, 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.delete = function() {
    confirm.generic($translate.instant('JS_SPACE.CONFIRM.DELETE_SUPPLIER'))
    .then(() => workshopAPI.delete('/vendor', $scope.vendor.id))
    .then(() => {
      $scope.form.$setPristine();
      $state.go('app.vendors');

      messages.show($translate.instant('JS_SPACE.MESSAGES.DELETED.DELETED'), 'success');
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.getBiller = function () {
    $scope.Vendor.getBiller()
    .then(biller => {
      $scope.billerSearch.selected = biller;

      $scope.vendor.biller_name = biller.company_name;
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.isPartstechVendor = function () {
    return partstechApi.isPartstechVendor($scope.vendor);
  };

  $scope.manuallyLinkVendor = function () {
    return $scope.Vendor.manuallyLinkVendor()
    .then(() => {
      messages.show('Vendor Linked', 'success');

      $state.reload();
    });
  };

  $scope.save = function (form) {
    if (!$scope.billerSearch.selected) {
      $scope.vendor.biller_id = null;
    }

    $scope.Vendor
      .save()
      .then(vendor => {
        $scope.vendor = vendor;

        form.$setPristine();
        $stateParams.id ? $state.reload() : $state.go('app.vendor', {id: vendor.id});

        messages.show($translate.instant('JS_SPACE.MESSAGES.SAVED.SUPPLIER'), 'success');
      })
      .catch(err => messages.error(err));
  };

  $scope.sendEmail = function () {
    messengerApi.email({
      name           : $scope.vendor.company_name,
      email_address  : $scope.vendor.email,
      body           : $scope.companyMessages.supplier_email_message,
      attachment_type: 5,
      vendor_id      : $scope.vendor.id
    })
    .then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.MESSAGE_SENT'), 'success');
    });
  };

  $scope.syncWithHeadOffice = function () {
    $q.when()
    .then(() => $scope.form.$dirty ? $scope.Vendor.save() : $q.when())
    .then(() => workshopAPI.patch('/vendors/sync_multibranch_vendor_entry', $scope.vendor))
    .then(() => {
      messages.show($translate.instant('MULTI_BRANCH_SPACE.VENDOR_LINKED'), 'success');

      $scope.form.$setPristine();

      $state.reload();
    })
    .catch(err => messages.error(err));
  };

  $scope.setCountryDefaults = function () {
    workshopAPI.get('/system/states_for_country', $scope.vendor.country_code)
    .then(states => {
      $scope.states = states;

      return workshopAPI.get('/system/country_validations', $scope.vendor.country_code);
    })
    .then(validations => {
      $scope.validations = validations;
    })
    .catch(err => {
      messages.error(err);
    });
  };

  if ($scope.Vendor.isBranchVendor()) {
    branchesApi.getVendorPayments($scope.Vendor.vendor)
    .then(payments => {
      $scope.branchPayments = payments;
    })
    .catch(err => {
      messages.error(err);
    });

    branchesApi.getVendorInvoices($scope.Vendor.vendor)
    .then(invoices => {
      $scope.branchInvoices = invoices;
    })
    .catch(err => {
      messages.error(err);
    });
  }

  if ($scope.vendor.country_code) {
    $scope.setCountryDefaults();
  }
  else {
    workshopAPI.get('/system/state_types/', null, 'states')
    .then(states => {
      $scope.states      = states;
      $scope.validations = sessionObjAPI.countryValidation();
    });
  }

  workshopAPI.get('/system/country_types/', null, 'country_types')
  .then(country_types => {
    $scope.country_types = country_types;
  });

  if ($scope.vendor.biller_id) {
    $scope.getBiller();
  }
}
