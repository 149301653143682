import 'angular';

import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';

import { InvoicesForBillerPaymentCtrl } from './invoices.for-biller-payment.ajs';
import { InvoicesForPaymentCtrl }       from './invoices.for-payment.ajs';
import { InvoiceStatusFilter }          from './invoice.status.filter.ajs';
import { InvoiceStatusPipe }            from './invoice.status.pipe';
import { InvoiceTypeFilter }            from './invoice.type.filter.ajs';
import { InvoiceTypePipe }              from './invoice.type.pipe';
import { LinkedInvoicesComponent }      from './linked-invoices.component';

angular.module('app-lite.invoices', [])
  .controller('InvoicesForBillerPaymentCtrl', InvoicesForBillerPaymentCtrl)
  .controller('InvoicesForPaymentCtrl', InvoicesForPaymentCtrl)
  .directive('linkedInvoices', downgradeComponent({ component: LinkedInvoicesComponent }))
  .filter('invoiceStatus', InvoiceStatusFilter)
  .filter('invoiceType', InvoiceTypeFilter)
  .service('invoiceStatusPipe', downgradeInjectable(InvoiceStatusPipe))
  .service('invoiceTypePipe', downgradeInjectable(InvoiceTypePipe));